.form-control::placeholder {
  color: #6c6c6c;
}
.landing-pageBox {
  /* padding: 2rem;
  padding-right: 38.125rem; */
  width: 100%;
}
body {
  position: static;
}
.loginBtn {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.loginBtn:hover {
  transform: scale(1.1);
}
.login-main-cont {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0%;
  z-index: -1;
  background-color: white;
  width: 100%;
  height: 100%;
}
.form {
  min-height: calc(100vh - 60vh);
  width: 60%;
}
.logo {
  margin-bottom: 20px;
}
.logo,
.title {
  text-align: center;
}
.logo-img {
  display: inline;
}
.background-img {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.form-control {
  border: none;
  /* border-radius:20px; */
  height: 4.8rem;
  color: #6c6c6c;
  background: #ececec;
}
.form-container {
  width: calc(100% - 70%);
  height: 100vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.signinBox__body div {
  width: 80%;
}
.btn-cont {
  text-align: center;
}

.signinBox__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.background {
  /* position: fixed; */
  top: 0;
  left: 0;
  z-index: -1;
  width: 70%;
}
.landing-pageBox__logo {
  width: 6.4375rem;
}
.landing-pageBox__banner {
  margin: 6rem 3.75rem 0 0;
  width: 43.5rem;
}

.signinBox {
  position: fixed;
  right: 0;
  top: 0;
  width: 30.125rem;
  min-width: 30.125rem;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #f5f4f4;
}
.signinBox__heading {
  padding: 2rem;
  padding-left: 0.3rem !important;
  font-size: 1.875rem;
  /* text-align: center; */
  display: flex !important;
  justify-content: flex-start !important;
  color: #000;
  text-transform: uppercase;
  font-family: "Avenir Next Bold";
  background: #8a86c0;
  /* background: url(./assets/background.png);  */
  display: flex;
  justify-content: center;
  /* border-bottom: 0.3rem solid #FFBE04; */
  width: 100%;
}
.signinBox__heading__container {
  width: 100%;
  display: flex;
}

.signinBox__heading div {
  width: 15rem;
  height: 6rem;
}
.signinBox__heading .left {
  background: url(./assets/Vector.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.signinBox__heading .right {
  /* background: url(./assets/koaLogo.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.signinBox__body {
  display: flex;
  justify-content: center;
  background: #fdfdfd;
  /* padding: 2.125rem 1.875rem 2.125rem 1.875rem; */
  overflow: auto;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
.btn-color {
  background: none !important;
  color: white !important;
  transition: 0.4s ease;
  border: none !important;
  box-shadow: none;
}
.btn-color:hover {
  color: white !important;
  border-radius: 5px;
}
.successful-registration {
  padding: 4rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.9;
  z-index: 10;
}

.loaderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.loaderContainer img {
  width: 5rem;
}
.loaderContainer-msg {
  text-align: left;
  font-size: 1.3rem;
  padding: 1rem 2rem;
}
.loaderContainer-msg h3 {
  color: #ee2326;
  text-align: initial;
  font-weight: bold;
}
.graphicTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.graphicBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
/* Desktop */

@media only screen and (max-width: 991px) {
  .background {
    width: 100%;
  }
  .form-container {
    width: 100%;
  }
  .background-img {
    object-fit: unset;
    width: 100%;
    height: auto;
    /* display: none; */
  }

  .signinBox {
    position: static;
    min-width: 0;
    width: 100%;
    height: 65vh;
    max-height: 100vh;
    min-height: 38rem;
    overflow: auto;
  }
  .landing-pageBox {
    padding: 1.35rem;
    min-height: auto;
    height: 40%;
    height: 35vh;
    max-height: 100vh;
    min-height: 20rem;
  }
  .landing-pageBox__banner {
    margin: 1.5rem 1rem 0 0;
    width: 20rem;
  }
  .landing-pageBox__logo {
    width: 4.25rem;
  }
  .signinBox__body {
    padding: 2rem;
    overflow: auto;
  }
}
.landing_page_img {
  width: 100%;
  height: 100vh;
  margin-right: 30rem;
}
.title.dnone.two {
  display: none;
  width: 100%;
}
/* Tablet */
@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 1000px) {
  .landing_page_img {
    display: none;
  }
  .form-container {
    flex-direction: column;
  }
  .logo-img {
    width: 50%;
    /* margin-top: 50px; */
  }
  .title.dnone.one {
    display: none;
  }
  .title.dnone.two {
    display: block;
  }
  .signinBox__body {
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    padding: 2.125rem 1.875rem 2.125rem 1.875rem;
    overflow: auto;
    /* height: auto; */
    position: absolute;
    bottom: 0;
    top: auto;
    left: 0;
    width: 100vw;
    /* overflow: hidden; */
  }
  .btn-cont input {
    margin-left: auto !important;
    width: 50% !important;
  }
  .form-control {
    display: flex;
    /* width:50%; */
  }
}
@media only screen and (max-width: 1300px) {
  .signinBox__body div {
    width: 80%;
  }
}
@media only screen and (max-width: 1000px) {
  .signinBox__body div {
    width: 100%;
  }
}
.title-two {
  display: none;
}
@media only screen and (max-width: 700px) {
  .dnone {
    display: none;
  }
  .logo-img {
    width: 80%;
  }
  .logo-img {
    width: 50%;
    /* margin-top: 50px; */
  }
  .title.dnone.one {
    display: none;
  }
  .title.dnone.two {
    display: block;
  }
  /* .title-two {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  .form {
    width: 80%;
  }
}
@media only screen and (max-width: 500px) {
  .form {
    width: 100%;
  }
}
@media only screen and (max-width: 300px) {
  .btn-color {
    min-width: 50px !important;
    font-size: 12px;
    height: 40px;
  }
  .logo2 {
    width: 120px;
  }
}
@media only screen and (max-width: 400px) {
  .form {
    width: 110%;
  }
}

@media only screen and (max-width: 991px) and (orientation: portrait) {
  .login-main-cont {
    flex-direction: column;
  }

  .form {
    margin-top: 20rem;
  }
}
