.footerBox {
  background: #532835 !important;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  z-index: 5;
}
.loggedin-page .stages-header__menu {
  display: block;
}
.loggedin-page .stages-header__menu li {
  margin-bottom: 1.5rem;
}

/* Icon Menu */
.bottom-icons-nav {
  position: relative;
  display: flex;
  list-style-type: none;
  /* background: #000; */
  z-index: 3;
  min-height: 5.375rem;
}
.bottom-icons-nav > li {
  width: 20%;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.bottom-icons-nav > li > a {
  color: rgba(255, 255, 255, 0.3);
  height: 100%;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  cursor: pointer;
}
.bottom-icons-nav > li > a.active {
  background: linear-gradient(180deg, #f68e1e 0%, #ffd100 100%) !important;
  color: rgba(255, 255, 255, 1);
}
.desktop__logo {
  padding: 1.1rem 1rem;
  opacity: 1;
  min-height: 9.375rem;
  display: none;
  background-color: #fff;
}

.icon-lg {
  font-size: 1.85rem;
}

.bottom-icons-nav.bottom-icons-nav--withtext > li {
  font-size: 1.25rem;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a {
  padding: 0.934rem 1rem;
  color: #fff;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a > i {
  margin-bottom: 0.75rem;
  display: inline-block;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a.active {
  border-left: 3px #fff solid;
  background: #ee2326;
  color: #fff !important;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a.active > i::before {
  color: #fff !important;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a > i::before {
  color: #fff !important;
}

.bottom-icons-nav.bottom-icons-nav--withtext > li > a > span {
  display: block;
  font-size: 0.75rem;
  line-height: 0.85rem;
}

.has-notification > i {
  position: relative;
}
.has-notification > i:after {
  position: absolute;
  content: "";
  right: -7px;
  top: -7px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #f34343;
}

.menuBackButton a span {
  font-size: 1.15rem !important;
}
/* Tablet */
@media only screen and (min-width: 991px) {
  .has-right-menu {
    position: relative;
    margin-right: 7rem;
    width: auto;
  }
  .loggedin-page .stages-header__menu {
    display: block;
    padding-right: 8rem;
  }

  .desktop__logo {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footerBox {
    left: auto;
    bottom: auto;
    right: 0;
    top: 0;
    z-index: 3;
    width: 7rem;
    height: 100%;
  }

  .bottom-icons-nav {
    padding-top: 0rem;
    flex-direction: column;
    height: 80%;
    overflow: auto;
    z-index: 5;
  }
  .bottom-icons-nav > li {
    margin: 1rem 0;
    width: 100%;
    font-size: 2rem;
  }
  .bottom-icons-nav > li > a {
    padding: 2rem 1rem;
  }
  .bottom-icons-nav.bottom-icons-nav--withtext > li > a {
    padding: 1rem 1rem;
  }
}

/* Desktop */
@media only screen and (min-width: 991px) {
}

@media (min-width: 1024px) {
  .ios-video {
    height: 100% !important;
    width: calc(100vw - 7rem) !important;
    position: fixed !important;
    left: 0 !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}
