.notificationContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30rem;
    height: auto;
    background: rgba(0, 0, 0, 0.35);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: white;
    font-size: 1.32rem;
    padding: 0.8rem 2rem;
    margin: 1rem;
    z-index: 55;
    border-radius: 0.5rem;
    transition: 0.4s;
}

.notification-hidden {
    opacity: 0;
    left: -40rem;
}

.notification-show {
    opacity: 1;
    left: 0;
}

.closeButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.closeButton-notification {
    background: url(./assets/close.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

@media only screen and (max-width: 991px) {
    .notificationContainer {
        top: 0%;
        bottom: auto;
        left: unset;
    }
    .notification-hidden {
        opacity: 0;
        top: -40rem;
    }
    .notification-show {
        opacity: 1;
        top: 0;
    }
}