.lobbyTutContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.562);
  z-index: 50;
  /* font-family: Calibri; */
  /* font-style: normal; */
}

.tutCardContainer {
  width: 25%;
  /* height: 50%; */
  background: #f5f4f4;
  border-radius: 0.2rem;
  height: auto;
  /* padding-bottom: 2rem; */
  /* color: #FFFFFF; */
}

.bg-red {
  background: #ee2326;
}

.pd-b-2 {
  padding-bottom: 1rem !important;
}

.tutCardContainer-sm {
  width: 80%;
}

.width-cal {
  width: 80%;
}

.tutCardContainer-header {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 0.2rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.1rem 2rem;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: bold;
  background: linear-gradient(132.51deg, #531e56 -1.72%, #834169 73.91%),
    linear-gradient(0deg, #db6f3f, #db6f3f);

  /* font-weight: bold; */
  font-size: 1.25rem;
  /* width: 95%; */
}
.w-95 {
  width: 95% !important;
}

.bottom-border {
  border-bottom: 0.3rem solid #ffbe04;
}

.right-border {
  border-right: 0.5rem solid #f57f28;
}

.tutCardContainer-header div {
  cursor: default;
}

.tutCardContainer-header img {
  width: 5rem;
  height: auto;
}
.noLeftPadding {
  padding-left: 0rem;
}
.ptb-extra {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
}

.tutCardButton {
  min-width: unset;
  height: 3.5rem;
  border: 0;
  opacity: 0.95;
  transform: scale(0.95);
  transition: 0.15s;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  line-height: unset;
  /* font-weight: 600; */
}

.tutCardButton:hover {
  opacity: 1;
  transform: scale(1);
  /* box-shadow: 0px 4px 11px #ffbc0465; */
}

.coloredHover:hover {
  box-shadow: 0px 4px 11px rgba(255, 255, 255, 0.25);
  color: white;
  background: #ec6208;
}

.tutCardContainer-body {
  width: 100%;
  height: auto;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-top: 0.8rem;
  padding: 1.5rem 1.5rem;
}

.tutCardContainer-body-extraPd {
  padding: 3.5rem 1.5rem;
}

.tutCardContainer-bodyItem {
  width: 100%;
  height: auto;
  min-height: 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1rem;
  font-size: 1.15rem;
  padding: 0.5rem;
}

.tutCardContainer-bodyItem img {
  /* box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.25); */
  transition: 0.15s;
  transform: scale(0.95);
  width: 5rem;
}

.tutCardContainer-bodyItem img:hover {
  transform: scale(1);
  box-shadow: 0px 4px 44px #8ecd9d;
}
.continueBtn {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.continueBtn:hover {
  transform: scale(1.1);
}
.tutCardContainer-bodyItem h2 {
  color: #db6f3f;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25rem;
}
.btn.btn-yellow {
  background: #532835 !important;
  color: #fff !important;
  transition: 0.4s ease;
}
.btn.btn-yellow:hover {
  background-color: #884b5e !important;
  color: #fff !important;
}
.bodyItem-text {
  margin-left: 0.5rem;
  cursor: default;
}

.tutCardContainer-body-textContainer {
  text-align: left;
  font-size: 1.3rem;
  padding: 1rem;
}

.tutCardContainer-body-textContainer h3 {
  color: #ee2326;
  text-align: initial;
  font-weight: bold;
}

.selfAlign {
  align-self: center;
}

@media only screen and (min-width: 991px) {
  .width-cal {
    width: 28%;
  }
  .tutCardContainer-sm {
    width: unset;
  }
  .tutCardContainer-bodyItem img {
    width: 6rem;
  }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {
  .tutCardContainer-body {
    max-height: 40vh;
  }

  .tutCardContainer {
    height: fit-content;
    margin-top: 2rem;
    width: unset;
  }

  .lobbyTutContainer {
    align-items: unset;
  }
}
